import pugFile from "./donutChart.pug";

export default function donutChartDirective() {
  return {
    scope: {},
    controller: "DonutChartController",
    controllerAs: "donutChart",
    template: pugFile(),
    bindToController: {
      chartData: "=",
      chartType: "@",
      reportId: "=",
      additionalData: "=",
      chartToggles: "=",
    },
  };
}
