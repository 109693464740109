/* eslint-env browser */

/* @ngInject */
export default class HeartRateService {
  constructor($injector) {
    this._$injector = $injector;
    this._Config = $injector.get("Config");
    this._$http = $injector.get("$http");
    this._features = this._Config.features;
    this._Authentication = $injector.get("Authentication");
  }

  getHeartRateData(startTime, endTime, studyId) {
    const url = `/heartRate/rawData/${studyId}`;
    const params = {startTime, endTime};
    return this._httpGet(url, params).then((response) => response.data);
  }

  _httpGet(url, params = {}) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._Config.apiUrl}`;
    return this._$http.get(`${baseUrl}${url}`, {
      headers: {
        Authorization: authHeader,
      },
      params,
    });
  }
}
