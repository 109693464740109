/* global angular */

/* @ngInject */
export default class DonutChartController {
  constructor($scope, $rootScope, $document, $element, DonutChartService, Config) {
    this._$document = $document;
    this._$element = $element;
    this._$scope = $scope;
    this._$rootScope = $rootScope;
    this._DonutChartService = DonutChartService;
    this.features = Config.features;

    this.NAME_PROPS = ["category", "name"];

    this.VALUE_PROPS = ["count", "displayedPercent", "duration"];

    this.$onInit = this._init;
  }

  get isDisabled() {
    if (!this.chartToggles) {
      return false;
    }
    return !this.chartToggles[this.chartType];
  }

  /// Private Functions ///

  /*
   * The following are passed in through directive attributes, and are not available until $onInit
   *   this.chartType
   *   this.chartData
   *   this.reportId
   *   this.additionalData
   *   this.isGeneratedReport
   *   this.chartToggles
   */
  _init() {
    if (this.chartType === "arrhythmiaBurden") {
      this.showPercentageNote = true;
    }
    this.isToggleDisplayed = !!this.chartToggles;
    const deregister = this._$rootScope.$on("report-graph-data-updated", (emittedEvent, reportId) => {
      if (reportId === this.reportId) {
        this.drawChart();
        this._$scope.$apply();
      }
    });
    this._$scope.$on("$destroy", deregister);
    this.drawChart();
  }

  drawChart() {
    this.chartStats = this._mapDataToStats();
    angular.element(() => {
      this._DonutChartService.showDonutChart(
        `${this.chartType}-${this.reportId}`,
        this.chartData,
        this.isDisabled
      );
    });
  }

  redrawChart() {
    this._DonutChartService.showDonutChart(
      `${this.chartType}-${this.reportId}`,
      this.chartData,
      this.isDisabled,
      0
    );
  }

  getIconColor(stat, index, totalSections) {
    return this._DonutChartService.getIconColor(stat, index, totalSections, this.isDisabled);
  }

  get title() {
    switch (this.chartType) {
      case "arrhythmiaEpisode":
        return "Arrhythmia Episodes";
      case "arrhythmiaBurden":
        return "Arrhythmia Burden";
      case "ventricularEctopy":
        return "Ventricular Ectopy";
      case "atrialFibrillationStats":
        return "Atrial Fibrillation Stats";
      default:
        return "Unknown Chart Type";
    }
  }

  _mapDataToStats() {
    return this.chartData.map((dataPoint) => {
      const name = this._getDataPointValue(dataPoint, this.NAME_PROPS);
      const value = this._getDataPointValue(dataPoint, this.VALUE_PROPS);
      return {name, value};
    });
  }

  _getDataPointValue(dataPoint, possiblePropNames) {
    let value;
    possiblePropNames.forEach((propName) => {
      if (dataPoint[propName] !== undefined) {
        value = dataPoint[propName];
      }
    });
    return value;
  }
}
