export default function leadDisconnectedTimelineDirective() {
  return {
    scope: {},
    controller: "LeadDisconnectedTimelineController",
    controllerAs: "leadDisconnectedTimeline",
    bindToController: {
      activeChannels: "=",
      connectionHistory: "=",
      itemId: "=",
    },
  };
}
