/* eslint-env browser */
import angular from "angular";
import * as d3 from "d3";
import moment from "moment";

import ganttLeads from "../../../d3/ganttLeads.js";

/* @ngInject */
export default class LeadDisconnectedTimelineController {
  constructor($scope, $document, $element, $rootScope, $window) {
    this._$scope = $scope;
    this._$document = $document;
    this._$element = $element;
    this._$rootScope = $rootScope;
    this._$window = $window;

    this.$onInit = this._init;

    const deregisterWindowResize = this._$rootScope.$on("window-resize", () => {
      this._createGraph();
    });
    this._$scope.$on("$destroy", deregisterWindowResize);
  }

  /// Public Functions ///

  get graphConfig() {
    return {
      startDate: moment().subtract(60, "minutes"),
      endDate: moment(),
      frequency: d3.timeMinute.every(10),
      tickFormat: d3.timeFormat("%H:%M"),
    };
  }

  /// Private Functions ///

  /*
   * The following are passed in through directive attributes, and are not available until $onInit
   *   this.activeChannels
   *   this.connectionHistory
   *   this.itemId
   */
  _init() {
    // add all properties of graphConfig to "this"
    Object.assign(this, this.graphConfig);
    this._createGraph();
  }

  _createGraph() {
    const channelNames = this.activeChannels.map((channel) => channel.name);
    const formattedData = this._formatConnectionData();

    const graphId = `leadDisconnectedTimeline-${this.itemId}`;
    const itemTitleRow = this._$document[0].getElementById(this.itemId);

    const configuredGanttLeads = ganttLeads(itemTitleRow, graphId, channelNames.length, {
      channels: channelNames,
      tickFrequency: this.frequency,
      tickFormat: this.tickFormat,
      timeDomainMode: "fixed",
      timeDomain: [this.startDate, this.endDate],
    });

    angular.element(() => {
      configuredGanttLeads(formattedData);

      // remove the previous graph
      const graphElement = this._$document[0].getElementById(graphId);
      if (graphElement && graphElement.childNodes.length > 1) {
        graphElement.removeChild(graphElement.firstChild);
      }
    });
  }

  _formatConnectionData() {
    const allConnectionData = this.connectionHistory;
    const formattedData = [];

    // For each active channel, get the channel's history
    this.activeChannels.forEach((activeChannel) => {
      const channelHistory = allConnectionData[activeChannel.channel - 1];
      // Iterate over the history for the channel and format the data for the graph
      for (let i = 0; i < channelHistory.length; i++) {
        const connectionStatus = channelHistory[i];
        const startDate = connectionStatus.disconnected || connectionStatus.connected;
        const endDate = this._getEndTimestamp(channelHistory, i);

        if (this._isWithinMostRecentHour(startDate, endDate)) {
          // The connection status has one property, either "connected" or "disconnected"
          const status = Object.keys(connectionStatus)[0];
          const formattedStatus = {
            startDate,
            endDate,
            taskName: activeChannel.name,
            status,
          };

          formattedData.push(formattedStatus);
        }
      }
    });

    return formattedData;
  }

  _isWithinMostRecentHour(connectionStart, connectionEnd) {
    return (
      moment(connectionStart).isBetween(this.startDate, this.endDate, null, "[]") ||
      moment(connectionEnd).isBetween(this.startDate, this.endDate, null, "[]")
    );
  }

  _getEndTimestamp(history, index) {
    let nextConnection;
    let endTimestamp = moment();
    // If there's another connection history item, it's start time is the current connection's end time
    if (index + 1 < history.length) {
      nextConnection = history[index + 1];
      endTimestamp = nextConnection.disconnected || nextConnection.connected;
    }
    return endTimestamp;
  }
}
