/* eslint-disable max-classes-per-file */
/* eslint-env browser */

class InboxItemSearchParams {
  constructor() {
    this.offset = 0;

    this.$and = []; // For Searches
  }
}

class NextAvailableMyItemsSearchParams extends InboxItemSearchParams {
  constructor() {
    super();
    this.completed = false;
    this.order = [["timestamp", "ASC"]];
    this.assignedToMe = true;
  }
}

class NextAvailableNotAssignedToMeSearchParams extends InboxItemSearchParams {
  constructor() {
    super();
    this.completed = false;
    this.order = [["timestamp", "ASC"]];
    this.assignedToMe = false;
  }
}

class MyItemsSearchParams extends InboxItemSearchParams {
  constructor() {
    super();
    this.assignedToMe = true;
    this.limit = 10;
    this.completed = false;
    this.order = [["timestamp", "ASC"]];
  }
}

class ItemsNotAssignedToMeSearchParams extends InboxItemSearchParams {
  constructor() {
    super();
    this.assignedToMe = false;
    this.limit = 50;
    this.completed = false;
    this.order = [["timestamp", "ASC"]];
  }
}

class CompletedItemsSearchParams extends InboxItemSearchParams {
  constructor() {
    super();
    this.limit = 50;
    this.completed = true;
    this.order = [["timestamp", "DESC"]];
  }
}

/* @ngInject */
export default class InboxItemSearchParamsService {
  constructor($injector) {
    this._$injector = $injector;
    this._WorkflowsService = $injector.get("WorkflowsService");

    this.features = $injector.get("Config").features;

    const Authentication = $injector.get("Authentication");
    const {
      user: {facilityId},
    } = Authentication.getJwtPayload();
    this._facilityId = facilityId;
    this._userId = Authentication.getUserId();

    this._newEventDestinationIsInbox = this._isNewEventDestinationInbox();
  }

  // Used to determine whether a pubsubbed item matches default params of a list
  itemMatchesDefaultSearchParams(item, defaultSearchParams, isTriageItem = false) {
    const matchingCompletionState = !!item.completed === defaultSearchParams.completed;
    const awaitingAlgorithm =
      this.features.algorithmClassification && item.algorithmClassification === "awaitingAlgorithm";
    const validStudyState = ![undefined, null, "archived", "failed"].includes(item.studyState);

    if (
      (item.type !== "Unconfigured Device On Patient" && !validStudyState) ||
      !matchingCompletionState ||
      awaitingAlgorithm
    ) {
      return false;
    }

    if (defaultSearchParams instanceof ItemsNotAssignedToMeSearchParams) {
      if (isTriageItem) {
        return (
          item.userClassification === null &&
          item.eventType !== null &&
          item.studyId !== null &&
          !item.assignedUserIds?.includes(this._userId)
        );
      }
      return (
        // Unclassified events are not displayed if destination is not inbox
        (this._newEventDestinationIsInbox || item.userClassification !== null) &&
        !item.assignedUserIds?.includes(this._userId)
      );
    }

    if (defaultSearchParams instanceof MyItemsSearchParams) {
      if (isTriageItem) {
        return (
          item.userClassification === null &&
          item.eventType !== null &&
          item.studyId !== null &&
          item.assignedUserIds?.includes(this._userId)
        );
      }
      return (
        // Unclassified events are not displayed if destination is not inbox
        (this._newEventDestinationIsInbox || item.userClassification !== null) &&
        item.assignedUserIds?.includes(this._userId)
      );
    }

    return true;
  }

  instantiate(searchParamsType) {
    let searchParams;
    switch (searchParamsType) {
      case "nextAvailableMyItems":
        searchParams = new NextAvailableMyItemsSearchParams();
        break;
      case "nextAvailableNotAssignedToMe":
        searchParams = new NextAvailableNotAssignedToMeSearchParams();
        break;
      case "myItems":
        searchParams = new MyItemsSearchParams();
        break;
      case "itemsNotAssignedToMe":
        searchParams = new ItemsNotAssignedToMeSearchParams();
        break;
      case "completedItems":
        searchParams = new CompletedItemsSearchParams();
        break;
      default:
        throw new Error(
          `Invalid search params type: ${searchParamsType} while instantiating InboxItemSearchParams`
        );
    }

    return searchParams;
  }

  _isNewEventDestinationInbox() {
    return (
      !this._WorkflowsService.workflowSettings[this._facilityId] ||
      this._WorkflowsService.workflowSettings[this._facilityId]?.newEventDestination === "Inbox"
    );
  }
}
