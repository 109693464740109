export default function arrhythmiaTimelineDirective() {
  return {
    scope: {},
    controller: "ArrhythmiaTimelineController",
    controllerAs: "arrhythmiaTimeline",
    bindToController: {
      heartRateTrend: "=",
      arrhythmiaData: "=",
      artifactRegions: "=",
      parentElementId: "=",
      reportId: "=",
      parentType: "=",
      studyStartDate: "=",
      studyEndDate: "=",
      isGeneratedReport: "=",
      chartToggles: "=",
      timeZone: "=",
    },
  };
}
